import { createStore } from 'redux'

const initialState = {
  sidebarShow: 'responsive',
  minimized: null,
  padding: 'custom-p-7',
  tooltip: {
	name: '',
	x: null,
	y: null,
  }
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store