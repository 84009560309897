import React, { Component } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import './scss/style.scss';
import PrivateRoute from './views/common/api/PrivateRoute';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/auth/Login'));
const ForgetPassword = React.lazy(() => import('./views/auth/ForgetPassword'));
const AccountSecurity = React.lazy(() => import('./views/auth/AccountSecurity'));
const Dashboard = React.lazy(() => import ('./views/dashboard/Dashboard'))

class App extends Component {

  render() {
    return (
      <BrowserRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              {/* <Route exact path="/" name="Login Page" render={props => <Login {...props}/>} /> */}
              <Route exact path="/" name="Login" render={() => { window.location.href = `/login`}} />
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/dashboard" name="Dashboard" render={props => <PrivateRoute><Dashboard {...props} /></PrivateRoute> } />
              <Route exact path="/forgetpassword" name="Forget Password" render={props =><ForgetPassword {...props}/>} />
			        <Route exact path="/accountsecurity" name="Account Security" render={props =><AccountSecurity {...props}/>} />
              <Route path="/" name="Home" render={props => <PrivateRoute><TheLayout {...props}/></PrivateRoute>} />
              {/* <Route exact path="/logout" name="Logout" render={() => { window.location.href = `/login`}} /> */}
            </Switch>
          </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;